import {createAsyncThunk} from "@reduxjs/toolkit"
import {FETCH_SESSION_THUNK, SessionState} from "../types"
import {DefaultExtraReducersTree, LoadingState} from "../../../../types/store"
import {ThunkApiConfig} from "../../../store"
import {fetchSession} from "../../../../api/lira/lira"
import {SessionData} from "../../../../api/lira/types"

const thunk = createAsyncThunk<SessionData, void, ThunkApiConfig>(
    FETCH_SESSION_THUNK,
    () => {
        return fetchSession()
    }
)

const reducers: DefaultExtraReducersTree<SessionState, SessionData> = {
    [thunk.pending.type]: state => {
        state.loadingStatus = LoadingState.LOADING
    },
    [thunk.fulfilled.type]: (state, {payload}) => {
        state.loadingStatus = LoadingState.LOADED

        state.data.firstName = payload.billTo?.firstName || ''
        state.data.lastName = payload.billTo?.lastName || ''
        state.data.country = payload.billTo?.country || ''
        state.data.stateProvince = payload.billTo?.stateProvince || ''
        state.data.postalCode = payload.billTo?.postalCode || ''
        state.data.paymentMethodId = payload.paymentMethodId
        state.data.makePrimaryCard = payload.makePrimaryCard ?? false
        state.flow = payload.flow
    },
    [thunk.rejected.type]: state => {
        state.loadingStatus = LoadingState.FAILED
    }
}

export {
    thunk,
    reducers
}
