import { ChaseFormStatus } from "../hooks/use-chase-form-status"
import { CustomerForm } from "../../../../../types/forms"
import { PaymentProvider } from "../../../../../types/payment-provider"

type SubmitDisabledCriteria = Record<'isValid' | 'isSubmitting', boolean> & {
  chaseFormStatus: ChaseFormStatus
  paymentProvider: Nullable<PaymentProvider>
  values: CustomerForm
}

const hasEmptyFields = <T extends Record<string, any>>(fields: T): boolean => {
  return Object.values(fields).some((field) => !field)
}

export const submitDisabled = ({
  chaseFormStatus,
  isValid,
  isSubmitting,
  paymentProvider,
  values,
}: SubmitDisabledCriteria): boolean => {
  return (
    !isValid ||
    isSubmitting ||
    chaseFormStatus === ChaseFormStatus.NOT_VALID ||
    (paymentProvider === PaymentProvider.AUTH_NET && (hasEmptyFields(values.address) || hasEmptyFields(values.card)))
  )
}
