import {useTranslation} from "react-i18next"
import {OverlayTrigger} from "@malwarebytes/react-components"
import {Row} from "@malwarebytes/react-components/build/row"
import styled from "styled-components"
import {VFC} from "react"
import {ReactComponent as HelpSVG} from "./images/help.svg"
import {VerificationOverlay} from "./overlay/overlay"

const TooltipTriggerIcon = styled(HelpSVG)`
  margin-left: 0.25rem;
  width: 14px;
  max-height: 14px;
  cursor: pointer;
  opacity: 0.7;
  line-height: 1rem;
  filter: grayscale(100);
  transition: 0.3s;
  
  &:hover {
    filter: grayscale(0);
  }
`

export const VerificationNumberLabel: VFC = () => {
    const { t } = useTranslation()

    return <Row className="py-0">
        <div className="d-flex">
            {t('form.card.verificationNumber')}

            <OverlayTrigger
                overlay={<VerificationOverlay/>}
                placement="top"
                trigger="hover"
            >
                <TooltipTriggerIcon />
            </OverlayTrigger>
        </div>
    </Row>
}
