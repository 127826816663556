import {getCardType, getPaymentProvider, getSupportedCardTypes} from "../../../../../../store/slices/card/selectors"
import * as Yup from "yup"
import {CustomerForm} from "../../../../../../types/forms"
import {useTranslation} from "react-i18next"
import {
    creditCardExpiration,
    creditCardNumber,
    creditCardSupported,
    creditCardVerificationNumber,
    postalCodeValid
} from "../../../../../../services/validation/yup-rules"
import {useCardStateSelector} from "../../../../../../store/slices/card/hooks"
import {getRegions} from "../../../../../../services/utility/geography/get-regions"
import {useAppSelector} from "../../../../../../store/hooks/use-app-selector"
import {useCallback, useMemo} from "react"
import {PaymentProvider} from "../../../../../../types/payment-provider"

export const useCustomerValidationSchema = (): unknown => {
    const {t} = useTranslation()

    const paymentProvider = useAppSelector(state => getPaymentProvider(state.card))

    const cardType = useCardStateSelector(state => getCardType(state))
    const supportedCardTypes = useCardStateSelector(state => getSupportedCardTypes(state))

    const cardSchema = useMemo(() => Yup.object({
        number: Yup
          .string()
          .required(t('validation.card.number.required'))
          .test(creditCardNumber())
          .test(creditCardSupported(supportedCardTypes)),
        expiration: Yup
          .string()
          .required(t('validation.card.expiration.required'))
          .test(creditCardExpiration()),
        verificationNumber: Yup
          .string()
          .required(t('validation.card.verificationNumber.required'))
          .test(creditCardVerificationNumber(cardType)),
    }), [cardType, supportedCardTypes])

    const addressBaseSchema = useCallback((values: CustomerForm) => Yup.object({
        country: Yup
            .string()
            .required(t('validation.address.country.required')),
        stateProvince: Yup
            .string()
            .when('country', {
                is: (c: string) => getRegions(c).length > 0,
                then: (s) => s.required(t('validation.address.stateProvince.required'))
            }),
        postalCode: Yup
            .string()
            .required(t('validation.address.postalCode.required'))
            .test(postalCodeValid(values.address.country, values.address.stateProvince)),
    }), [])

    const namesSchema = useMemo(() => Yup.object({
        firstName: Yup
            .string()
            .required(t('validation.address.firstName.required'))
            .max(50, t('validation.address.firstName.max', {size: 50})),
        lastName: Yup
            .string()
            .required(t('validation.address.lastName.required'))
            .max(50, t('validation.address.lastName.max', {size: 50})),
    }), [])

    const addressSchema = useCallback((values: CustomerForm) => {
        if (paymentProvider === PaymentProvider.CHASE_DHPP) {
            return addressBaseSchema(values)
        }

        return addressBaseSchema(values).concat(namesSchema)
    }, [
        paymentProvider,
        addressBaseSchema,
        namesSchema
    ])

    return useCallback(() => {
        return Yup.lazy((values: CustomerForm) => {
            if (paymentProvider === PaymentProvider.CHASE_DHPP) {
                // We can omit card validation in Chase flows because we are rendering their hosted form which handles the validation for us
                return Yup.object({
                    address: addressSchema(values)
                })
            }

            return Yup.object({
                address: addressSchema(values),
                card: cardSchema
            })
        })
    }, [
        addressSchema,
        cardSchema,
        paymentProvider
    ])
}
