import {PaymentProvider} from "../types/payment-provider"
import {CreditCardType} from "../types/credit-card"
import {msFromSeconds, stringFromEnv} from "../services/utility/helpers/config/string-from-env"
import {booleanFromEnv} from "../services/utility/config/helpers"
import {AcceptJsConfig} from "../types/provider/accept-js"
import {ApiConfig, PollingConfiguration, ServerProvidedConfiguration} from "../types/config.types"
import {ThemeType} from "../types/theme"

type PaymentProviderConfig<Settings> = {
    supportedCards: CreditCardType[],
    settings: Settings
}

type PostMessageConfig = {
    enableOriginRestriction: boolean,
    targetOrigins: string[],
}

type TimeoutSettings = {
    serverProvided: ServerProvidedConfiguration,
    polling: PollingConfiguration
}

type Config = {
    app: {
        env: string,
        defaultTheme: ThemeType,
        selfUrl: string
    },
    marketing: {
        ga: {
            id: string,
        },
    },
    payment: {
        maxElapsedYear: number,
        providers: {
            [PaymentProvider.AUTH_NET]: PaymentProviderConfig<AcceptJsConfig>,
            [PaymentProvider.CHASE_DHPP]: PaymentProviderConfig<{
                executePayUrl: string
            }>,
        }
    },
    api: Record<'jupiter' | 'lira', ApiConfig>,
    iframe: {
        postMessage: PostMessageConfig,
        activityTracking: {
            debounceWithin: number
        }
    },
    timeout: {
        processing: {
            authNet: TimeoutSettings,
            chase: TimeoutSettings,
        },
        delivering: TimeoutSettings
    },
    pusher: {
        key: string,
        cluster: string
    },
    logger: {
        enabled: boolean,
        level: string,
    },
    chase: {
        scriptUrl: string,
        collectingStatus: {
            maxAttempts: number,
            delayBetweenAttemptsInMs: number
        }
    }
}

export const config: Config = {
    app: {
        env: stringFromEnv(process.env.REACT_APP_APPLICATION_ENV),
        defaultTheme: ThemeType.LIGHT,
        selfUrl: stringFromEnv(process.env.REACT_APP_SELF_URL)
    },
    marketing: {
        ga: {
            id: stringFromEnv(process.env.REACT_APP_GA_ID) || 'none',
        },
    },
    payment: {
        maxElapsedYear: 80, // Used by 'card-validator' to determine how many years in the future a card can be valid,
        providers: {
            [PaymentProvider.AUTH_NET]: {
                supportedCards: [
                    CreditCardType.VISA,
                    CreditCardType.MASTERCARD,
                    CreditCardType.AMEX,
                    CreditCardType.DISCOVER,
                ],
                settings: {
                    scriptUrl: stringFromEnv(process.env.REACT_APP_ACCEPT_JS_SCRIPT_URL),
                    apiLoginID: stringFromEnv(process.env.REACT_APP_ACCEPT_JS_LOGIN_ID),
                    clientKey: stringFromEnv(process.env.REACT_APP_ACCEPT_JS_CLIENT_KEY)
                }
            },
            [PaymentProvider.CHASE_DHPP]: {
                supportedCards: [
                    CreditCardType.VISA,
                    CreditCardType.MASTERCARD,
                    CreditCardType.MAESTRO,
                ],
                settings: {
                    executePayUrl: stringFromEnv(process.env.REACT_APP_CHASE_EXECUTE_PAY_URL)
                }
            },
        }
    },
    api: {
        jupiter: {
            url: stringFromEnv(process.env.REACT_APP_JUPITER_URL)
        },
        lira: {
            url: stringFromEnv(process.env.REACT_APP_LIRA_URL)
        }
    },
    iframe: {
        postMessage: {
            enableOriginRestriction: booleanFromEnv(process.env.REACT_APP_ENABLE_POST_MESSAGE_ORIGIN_RESTRICTION),
            targetOrigins: [
                stringFromEnv(process.env.REACT_APP_ONEVIEW_URL),
                stringFromEnv(process.env.REACT_APP_ONEVIEW_URL_SECONDARY),
                stringFromEnv(process.env.REACT_APP_NEBULA_URL),
                stringFromEnv(process.env.REACT_APP_NEBULA_URL_SECONDARY),
            ],
        },
        activityTracking: {
            debounceWithin: msFromSeconds(5)
        }
    },
    timeout: {
        processing: {
            authNet: {
                serverProvided: {
                    waitFor: msFromSeconds(15)
                },
                polling: {
                    interval: msFromSeconds(1),
                    waitFor: msFromSeconds(15)
                }
            },
            chase: {
                serverProvided: {
                    waitFor: msFromSeconds(15)
                },
                polling: {
                    interval: msFromSeconds(1),
                    waitFor: msFromSeconds(15)
                }
            }
        },
        delivering: {
            serverProvided: {
                waitFor: msFromSeconds(5)
            },
            polling: {
                interval: msFromSeconds(1),
                waitFor: msFromSeconds(15)
            }
        }
    },
    pusher: {
        key: stringFromEnv(process.env.REACT_APP_PUSHER_KEY),
        cluster: stringFromEnv(process.env.REACT_APP_PUSHER_CLUSTER)
    },
    logger: {
        enabled: booleanFromEnv(process.env.REACT_APP_LOGGER_ENABLED),
        level: stringFromEnv(process.env.REACT_APP_LOG_LEVEL),
    },
    chase: {
        scriptUrl: stringFromEnv(process.env.REACT_APP_CHASE_SCRIPT_URL),
        collectingStatus: {
            maxAttempts: 10,
            delayBetweenAttemptsInMs: 2500,
        },
    }
}
